import { useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import Spin from 'antd/es/spin';
import Select, { type SelectProps } from 'antd/es/select';
import { useTranslation } from 'react-i18next';
import Input from 'antd/es/input/Input';
import Button from '../../Button/Button';

export interface DebounceSelectProps extends Omit<SelectProps<any>, 'options'> {
  fetchOptions: (search: string) => Promise<any[]>;
  debounceTimeout?: number;
  exludeValues?: unknown[];
  defaultKey?: string;
  addAutoToken?: boolean;
}

function DebounceSelect({
  fetchOptions,
  addAutoToken,
  debounceTimeout = 800,
  className,
  exludeValues = [],
  ...props
}: DebounceSelectProps) {
  const [textMode, setTextMode] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [fetching, setFetching] = useState(false);
  const fetchRef = useRef(0);
  const [search, setSearch] = useState<string>(() => props.defaultKey || '');
  const { t } = useTranslation();

  useEffect(() => {
    debounceFetcher(search);
  }, [search, fetchOptions]);

  const debounceFetcher = useMemo(() => {
    const loadOptions = async (search: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      const newOptions = await fetchOptions(search);
      if (fetchId === fetchRef.current) {
        setOptions((prevOptions) => newOptions.concat(prevOptions.filter((option) => option.isNewToken)));
      }

      if (fetchId === fetchRef.current) {
        setFetching(false);
      }
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout, fetchRef]);

  const button = useMemo(() => {
    if (addAutoToken)
      return (
        <Button
          onClick={() => setTextMode((prev) => !prev)}
          className="absolute right-1 top-1 h-[40px] rounded-md text-sm hover:border-none hover:outline-none"
          type="primary-inverse"
        >
          {textMode ? t('general.buttons.select') : t('general.buttons.enter')}
        </Button>
      );
    return null;
  }, [setTextMode, addAutoToken, textMode]);

  if (textMode)
    return (
      <div className="relative flex items-center">
        <Input
          value={props.value}
          className="h-[50px] w-full"
          //@ts-ignore
          onChange={props.onChange}
        />
        {button}
      </div>
    );
  return (
    <div className="relative flex items-center">
      <Select
        className={`h-[50px] w-full ${className}`}
        filterOption={false}
        searchValue={search}
        onSearch={(search) => setSearch(search)}
        showSearch
        notFoundContent={fetching ? <Spin size="small" /> : t('general.texts.emptyData')}
        {...props}
        options={options.filter((option) => !exludeValues.includes(option.value))}
        maxTagCount="responsive"
      />
      {button}
    </div>
  );
}

export default DebounceSelect;
