export const COUNTRIES = {
  FRANCE: 'France',
  GERMANY: 'Germany',
};
export const IDENTITY_VERIFICATION_COUNTRIES = [COUNTRIES.GERMANY];
export const COUNTRIES_IDS = {
  [COUNTRIES.FRANCE]: 1,
  [COUNTRIES.GERMANY]: 2,
};

export enum BULK_OPERATION {
  SIM = 'sims',
  MVN = 'mvns',
  SDA = 'sdas',
  TRUNK = 'trunks',
  SDA_REGISTRATION = 'sdasRegistrations',
  SIM_REGISTRATION = 'simsRegistrations',
  SIM_USAGE = 'simsUsages',
  SIM_SUSPENSION_RESUME = 'simsSuspensionsResume',
  SIM_SWITCH = 'simsSwitch',
  MOBILE_FIRST = 'mobileFirst',
}

export enum PHONE_NUMBER_TYPES {
  FIXE = 'fix',
  MOBILE = 'mobile',
  RSVA = 'rsva',
}

export enum SERVICE_OPERATIONS {
  ACTIVATION = 'activation',
  MODIFICATION = 'modification',
  REGISTRATION = 'registration',
  SUSPENSION_RESUME = 'suspensionResume',
  SWITCH = 'switch',
  USAGE = 'usage',
  DELETE = 'delete',
}

export enum SERVICES_TYPES {
  TRUNK = 'trunk',
  TRUNK_VOICE = 'trunk_voix',
  TRUNK_SMS_P2P = 'trunk_sms_p2p',
  TRUNK_SMS_A2P = 'trunk_sms_a2p',
  MOBILE_SIM = 'mobile_sim',
  MOBILE_FIRST = 'mobile_first',
  MOBILE_MVN = 'mvn',
  MOBILE_SDA = 'sda',
}

export const SERVICES_TYPES_OPTIONS = [
  { label: SERVICES_TYPES.TRUNK, value: SERVICES_TYPES.TRUNK },
  { label: SERVICES_TYPES.TRUNK_VOICE, value: SERVICES_TYPES.TRUNK_VOICE },
  { label: SERVICES_TYPES.TRUNK_SMS_A2P, value: SERVICES_TYPES.TRUNK_SMS_A2P },
  { label: SERVICES_TYPES.TRUNK_SMS_P2P, value: SERVICES_TYPES.TRUNK_SMS_P2P },
  { label: SERVICES_TYPES.MOBILE_SDA, value: SERVICES_TYPES.MOBILE_SDA },
  { label: SERVICES_TYPES.MOBILE_MVN, value: SERVICES_TYPES.MOBILE_MVN },
  { label: SERVICES_TYPES.MOBILE_FIRST, value: SERVICES_TYPES.MOBILE_FIRST },
  { label: SERVICES_TYPES.MOBILE_SIM, value: SERVICES_TYPES.MOBILE_SIM },
];

export const PHONE_NUMBER_TYPES_BY_SERVICES_TYPES_OPTIONS = {
  [PHONE_NUMBER_TYPES.FIXE]: [
    {
      label: `form.inputs.services_operations.values.${SERVICES_TYPES.TRUNK_VOICE}`,
      value: SERVICES_TYPES.TRUNK_VOICE,
    },
  ],
  [PHONE_NUMBER_TYPES.MOBILE]: [
    {
      label: `form.inputs.services_operations.values.${SERVICES_TYPES.MOBILE_FIRST}`,
      value: SERVICES_TYPES.MOBILE_FIRST,
    },
    {
      label: `form.inputs.services_operations.values.${SERVICES_TYPES.MOBILE_SIM}`,
      value: SERVICES_TYPES.MOBILE_SIM,
    },
    {
      label: `form.inputs.services_operations.values.${SERVICES_TYPES.TRUNK_VOICE}`,
      value: SERVICES_TYPES.TRUNK_VOICE,
    },
    {
      label: `form.inputs.services_operations.values.${SERVICES_TYPES.TRUNK_SMS_A2P}`,
      value: SERVICES_TYPES.TRUNK_SMS_A2P,
    },
    {
      label: `form.inputs.services_operations.values.${SERVICES_TYPES.TRUNK_SMS_P2P}`,
      value: SERVICES_TYPES.TRUNK_SMS_P2P,
    },
  ],
  [PHONE_NUMBER_TYPES.RSVA]: [
    {
      label: `form.inputs.services_operations.values.${SERVICES_TYPES.TRUNK_VOICE}`,
      value: SERVICES_TYPES.TRUNK_VOICE,
    },
  ],
};

export enum COMMAND {
  MVN = 'mvns',
  SIM = 'sims',
  SDA = 'sdas',
  TRUNK = 'trunks',
  MOBILE_FIRST = 'mobileFirst',
}
export enum ACCOUNT_TYPE {
  COMPANY = 'company',
  INDIVIDUAL = 'individual',
}
export enum BOOL {
  YES = 'YES',
  NO = 'NO',
}

export enum IDENTITY_PROOF_TYPE {
  IDENTITY_CARD = 'identity_card',
  DRIVER_LICENCE = 'driver_licence',
  PASSPORT = 'passport',
  RESIDENT_PERMIT = 'residence_permit',
  COMPANY_REGISTRATION_DOC = 'company_registration_document',
}

export const COUNTRY_MAP_IDENTITY_PROOF_TYPE_OPTIONS = {
  [COUNTRIES.GERMANY]: {
    [ACCOUNT_TYPE.INDIVIDUAL]: [
      {
        label: 'form.inputs.identityProofType.values.identity_card',
        value: IDENTITY_PROOF_TYPE.IDENTITY_CARD,
      },
      {
        label: 'form.inputs.identityProofType.values.driver_licence',
        value: IDENTITY_PROOF_TYPE.DRIVER_LICENCE,
      },
      {
        label: 'form.inputs.identityProofType.values.passport',
        value: IDENTITY_PROOF_TYPE.PASSPORT,
      },
      {
        label: 'form.inputs.identityProofType.values.residence_permit',
        value: IDENTITY_PROOF_TYPE.RESIDENT_PERMIT,
      },
    ],
    [ACCOUNT_TYPE.COMPANY]: [
      {
        label: 'form.inputs.identityProofType.values.company_registration_document',
        value: IDENTITY_PROOF_TYPE.COMPANY_REGISTRATION_DOC,
      },
    ],
  },
};

export enum ACCOUNT_STATUS {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  VERIFIED = 'verified',
  FAILED = 'failed',
}
export const ACCOUNT_STATUS_OPTIONS = [
  {
    label: 'form.inputs.accountStatus.values.pending',
    value: ACCOUNT_STATUS.PENDING,
  },
  {
    label: 'form.inputs.accountStatus.values.in_progress',
    value: ACCOUNT_STATUS.IN_PROGRESS,
  },
  {
    label: 'form.inputs.accountStatus.values.verified',
    value: ACCOUNT_STATUS.VERIFIED,
  },
  {
    label: 'form.inputs.accountStatus.values.failed',
    value: ACCOUNT_STATUS.FAILED,
  },
];

export enum MOBILE_SERVICES {
  VOICE = 'voix',
  SMS = 'sms',
  VOICE_SMS = 'voix+sms',
  DATA = 'data',
  DATA_FR = 'data_fr',
  MF_V = 'MF',
}
export enum propositionType {
  'ENREACH',
  'LEGOS',
}
export const MOBILE_SERVICES_OPTIONS = [
  {
    label: 'form.inputs.services.values.voix',
    value: MOBILE_SERVICES.VOICE,
  },
  {
    label: 'form.inputs.services.values.sms',
    value: MOBILE_SERVICES.SMS,
  },
  {
    label: 'form.inputs.services.values.voix+sms',
    value: MOBILE_SERVICES.VOICE_SMS,
  },
  {
    label: 'form.inputs.services.values.data',
    value: MOBILE_SERVICES.DATA,
  },
  {
    label: 'form.inputs.services.values.data_fr',
    value: MOBILE_SERVICES.DATA_FR,
  },
  {
    label: 'form.inputs.services.values.MF_V',
    value: MOBILE_SERVICES.MF_V,
  },
];
export const PHONE_NUMBER_TYPES_OPTIONS = [
  {
    label: 'form.inputs.phoneNumberTypes.values.fix',
    value: PHONE_NUMBER_TYPES.FIXE,
  },
  {
    label: 'form.inputs.phoneNumberTypes.values.mobile',
    value: PHONE_NUMBER_TYPES.MOBILE,
  },
  {
    label: 'form.inputs.phoneNumberTypes.values.rsva',
    value: PHONE_NUMBER_TYPES.RSVA,
  },
];

export enum SIM_SUSPENDRESUME_REQUEST {
  SUSPENSION = 'suspension',
  REACTIVATION = 'reactivation',
  SIM_SWAP = 'simswap',
}

export const SIM_SUSPENDRESUME_REQUEST_OPTIONS = [
  {
    label: 'form.inputs.suspendResumeRequest.values.suspension',
    value: SIM_SUSPENDRESUME_REQUEST.SUSPENSION,
  },
  {
    label: 'form.inputs.suspendResumeRequest.values.reactivation',
    value: SIM_SUSPENDRESUME_REQUEST.REACTIVATION,
  },
  {
    label: 'form.inputs.suspendResumeRequest.values.simswap',
    value: SIM_SUSPENDRESUME_REQUEST.SIM_SWAP,
  },
];

export enum MNO_VALUES {
  ORANGE = 'Orange',
  SISTEER = 'BTBD',
}

export const MNO_OPTIONS = [
  {
    label: MNO_VALUES.ORANGE,
    value: MNO_VALUES.ORANGE,
  },
  {
    label: MNO_VALUES.SISTEER,
    value: MNO_VALUES.SISTEER,
  },
];

export enum FILE_TAGS {
  ADDRESS_PROOF = 'address_proof',
  IDENTITY_PROOF_FRONT = 'identity_proof_front',
  IDENTITY_PROOF_BACK = 'identity_proof_back',
  COMPANY_REGISTRATION = 'company_registration_document',
  REGULATOR_CERTIFICATE = 'regulator_certificate',
}
export const FILE_TAGS_OPTIONS = {
  [ACCOUNT_TYPE.COMPANY]: [
    {
      value: FILE_TAGS.COMPANY_REGISTRATION,
      label: 'form.inputs.tag.values.company_registration_document',
    },
    {
      value: FILE_TAGS.ADDRESS_PROOF,
      label: 'form.inputs.tag.values.address_proof',
    },
  ],
  [ACCOUNT_TYPE.INDIVIDUAL]: [
    {
      value: FILE_TAGS.ADDRESS_PROOF,
      label: 'form.inputs.tag.values.address_proof',
    },
    {
      value: FILE_TAGS.IDENTITY_PROOF_FRONT,
      label: 'form.inputs.tag.values.identity_proof_front',
    },
    {
      value: FILE_TAGS.IDENTITY_PROOF_BACK,
      label: 'form.inputs.tag.values.identity_proof_back',
    },
  ],
};

export enum PHONE_NUMBER_OFFERS {
  TRUNK = 'trunk',
  MVN = 'mvn',
  MOBILE = 'mobile',
  MOBILE_LIGHT = 'mobile_light',
  MOBILE_FULL = 'mobile_full',
}

export const ADMIN_PERMISSION = 'admin';

export const PHONE_NUMBER_OFFERS_OPTIONS = [
  {
    label: 'form.inputs.offer.values.trunk',
    value: PHONE_NUMBER_OFFERS.TRUNK,
  },
  {
    label: 'form.inputs.offer.values.mvn',
    value: PHONE_NUMBER_OFFERS.MVN,
  },
  {
    label: 'form.inputs.offer.values.mobile',
    value: PHONE_NUMBER_OFFERS.MOBILE,
  },
  {
    label: 'form.inputs.offer.values.mobile_light',
    value: PHONE_NUMBER_OFFERS.MOBILE_LIGHT,
  },
  {
    label: 'form.inputs.offer.values.mobile_full',
    value: PHONE_NUMBER_OFFERS.MOBILE_FULL,
  },
];

export const PHONE_NUMBER_INTERCO = {
  CPTL_COLMAR: 'cptl_colmar',
  CPTL_COURBEVOIE: 'cptl_courbevoie',
  FT_COURBEVOIE: 'ft_courbevoie',
  SFR_MOBILE: 'sfr_mobile',
};

export const PHONE_NUMBER_INTERCO_OPTIONS = [
  {
    label: 'form.inputs.interco.values.cptl_colmar',
    value: PHONE_NUMBER_INTERCO.CPTL_COLMAR,
  },
  {
    label: 'form.inputs.interco.values.cptl_courbevoie',
    value: PHONE_NUMBER_INTERCO.CPTL_COURBEVOIE,
  },
  {
    label: 'form.inputs.interco.values.ft_courbevoie',
    value: PHONE_NUMBER_INTERCO.FT_COURBEVOIE,
  },
  {
    label: 'form.inputs.interco.values.sfr_mobile',
    value: PHONE_NUMBER_INTERCO.SFR_MOBILE,
  },
];
export enum PHONE_NUMBER_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  SUSPEND_IMPAYE = 'suspend_impaye',
  SUSPEND = 'suspend',
  EN_COURS_OUVERTURE = 'en_cours_ouverture',
}

export const PHONE_NUMBER_STATUS_OPTIONS = [
  {
    label: 'form.inputs.phoneNumberStatus.values.active',
    value: PHONE_NUMBER_STATUS.ACTIVE,
  },
  {
    label: 'form.inputs.phoneNumberStatus.values.inactive',
    value: PHONE_NUMBER_STATUS.INACTIVE,
  },
  {
    label: 'form.inputs.phoneNumberStatus.values.suspend_impaye',
    value: PHONE_NUMBER_STATUS.SUSPEND_IMPAYE,
  },
  {
    label: 'form.inputs.phoneNumberStatus.values.suspend',
    value: PHONE_NUMBER_STATUS.SUSPEND,
  },
  {
    label: 'form.inputs.phoneNumberStatus.values.en_cours_ouverture',
    value: PHONE_NUMBER_STATUS.EN_COURS_OUVERTURE,
  },
];

export enum PHONE_NUMBER_SLICES_STATE {
  AFFECTEE = 'Affectee',
  LIBRE = 'Libre',
  RESERVEE = 'Reservee',
  DEMANDEE = 'Demandee',
  LIVREE = 'Livree',
}

export const PHONE_NUMBER_SLICES_STATE_OPTIONS = [
  {
    label: 'form.inputs.numberSlicesState.values.Affectee',
    value: PHONE_NUMBER_SLICES_STATE.AFFECTEE,
  },
  {
    label: 'form.inputs.numberSlicesState.values.Libre',
    value: PHONE_NUMBER_SLICES_STATE.LIBRE,
  },
  {
    label: 'form.inputs.numberSlicesState.values.Reservee',
    value: PHONE_NUMBER_SLICES_STATE.RESERVEE,
  },
  {
    label: 'form.inputs.numberSlicesState.values.Demandee',
    value: PHONE_NUMBER_SLICES_STATE.DEMANDEE,
  },
  {
    label: 'form.inputs.numberSlicesState.values.Livree',
    value: PHONE_NUMBER_SLICES_STATE.LIVREE,
  },
];
export enum PHONE_PORTA_STATUS {
  OK = 'OK',
  IN_PROGRESS = 'IN_PROGRESS',
  CANCELED = 'CANCELED',
  REQUESTED = 'REQUESTED',
  NO = 'NO',
  OUTGOING = 'OUTGOING',
  RESTIT = 'RESTIT',
}
export const PHONE_PORTA_STATUS_OPTIONS = [
  {
    label: 'form.inputs.phoneNumberPortaStatus.values.OK',
    value: PHONE_PORTA_STATUS.OK,
  },
  {
    label: 'form.inputs.phoneNumberPortaStatus.values.IN_PROGRESS',
    value: PHONE_PORTA_STATUS.IN_PROGRESS,
  },
  {
    label: 'form.inputs.phoneNumberPortaStatus.values.CANCELED',
    value: PHONE_PORTA_STATUS.CANCELED,
  },
  {
    label: 'form.inputs.phoneNumberPortaStatus.values.REQUESTED',
    value: PHONE_PORTA_STATUS.REQUESTED,
  },
  {
    label: 'form.inputs.phoneNumberPortaStatus.values.NO',
    value: PHONE_PORTA_STATUS.NO,
  },
  {
    label: 'form.inputs.phoneNumberPortaStatus.values.OUTGOING',
    value: PHONE_PORTA_STATUS.OUTGOING,
  },
  {
    label: 'form.inputs.phoneNumberPortaStatus.values.RESTIT',
    value: PHONE_PORTA_STATUS.RESTIT,
  },
];

export const SDA_PORTA_STATUS = [
  {
    label: 'form.inputs.phoneNumberPortaStatus.values.OK',
    value: PHONE_PORTA_STATUS.OK,
  },
  {
    label: 'form.inputs.phoneNumberPortaStatus.values.IN_PROGRESS',
    value: PHONE_PORTA_STATUS.IN_PROGRESS,
  },
  {
    label: 'form.inputs.phoneNumberPortaStatus.values.CANCELED',
    value: PHONE_PORTA_STATUS.CANCELED,
  },
  {
    label: 'form.inputs.phoneNumberPortaStatus.values.REQUESTED',
    value: PHONE_PORTA_STATUS.REQUESTED,
  },
  {
    label: 'form.inputs.phoneNumberPortaStatus.values.NO',
    value: PHONE_PORTA_STATUS.NO,
  },
  {
    label: 'form.inputs.phoneNumberPortaStatus.values.OUTGOING',
    value: PHONE_PORTA_STATUS.OUTGOING,
  },
  {
    label: 'form.inputs.phoneNumberPortaStatus.values.RESTIT',
    value: PHONE_PORTA_STATUS.RESTIT,
  },
];

export enum TRUNK_PORTA_STATUS {
  A_CONFIRMER = 'A CONFIRMER',
  ANNULATION_EN_COURS = 'ANNULATION EN COURS',
  ANNULE = 'ANNULE',
  REFUSE = 'REFUSE',
  DEMACTIV = 'DEMACTIV',
  DEMPORT = 'DEMPORT',
  EN_ATTENTE = 'EN ATTENTE',
  EN_ATTENTE_PLUS = 'EN ATTENTE +',
  EN_ATTENTE_VALIDATION_FIAB_CLIENT = 'EN ATTENTE VALIDATION FIAB CLIENT',
  REPORT_EN_COURS = 'REPORT EN COURS',
  AR = 'AR',
  AR_PLUS = 'AR +',
  CI = 'CI',
  OK = 'OK',
}

export const TRUNK_PORTA_STATUS_OPTIONS = [
  {
    label: 'form.inputs.trunkPortaStatus.values.A CONFIRMER',
    value: TRUNK_PORTA_STATUS.A_CONFIRMER,
  },
  {
    label: 'form.inputs.trunkPortaStatus.values.ANNULATION EN COURS',
    value: TRUNK_PORTA_STATUS.ANNULATION_EN_COURS,
  },
  {
    label: 'form.inputs.trunkPortaStatus.values.ANNULE',
    value: TRUNK_PORTA_STATUS.ANNULE,
  },
  {
    label: 'form.inputs.trunkPortaStatus.values.REFUSE',
    value: TRUNK_PORTA_STATUS.REFUSE,
  },
  {
    label: 'form.inputs.trunkPortaStatus.values.DEMACTIV',
    value: TRUNK_PORTA_STATUS.DEMACTIV,
  },
  {
    label: 'form.inputs.trunkPortaStatus.values.DEMPORT',
    value: TRUNK_PORTA_STATUS.DEMPORT,
  },
  {
    label: 'form.inputs.trunkPortaStatus.values.EN ATTENTE',
    value: TRUNK_PORTA_STATUS.EN_ATTENTE,
  },
  {
    label: 'form.inputs.trunkPortaStatus.values.EN ATTENTE +',
    value: TRUNK_PORTA_STATUS.EN_ATTENTE_PLUS,
  },
  {
    label: 'form.inputs.trunkPortaStatus.values.EN ATTENTE VALIDATION FIAB CLIENT',
    value: TRUNK_PORTA_STATUS.EN_ATTENTE_VALIDATION_FIAB_CLIENT,
  },
  {
    label: 'form.inputs.trunkPortaStatus.values.REPORT EN COURS',
    value: TRUNK_PORTA_STATUS.REPORT_EN_COURS,
  },
  {
    label: 'form.inputs.trunkPortaStatus.values.AR',
    value: TRUNK_PORTA_STATUS.AR,
  },
  {
    label: 'form.inputs.trunkPortaStatus.values.AR PLUS',
    value: TRUNK_PORTA_STATUS.AR_PLUS,
  },
  {
    label: 'form.inputs.trunkPortaStatus.values.CI',
    value: TRUNK_PORTA_STATUS.CI,
  },
  {
    label: 'form.inputs.trunkPortaStatus.values.OK',
    value: TRUNK_PORTA_STATUS.OK,
  },
];

export enum COMMAND_STATUS {
  OK = 'ok',
  FAILED = 'failed',
  WAITING = 'waiting',
  PENDING = 'pending',
  PENDING_PORTA = 'pending_porta',
  PENDING_PORTA_INTERNE = 'pending_porta_interne',
}

export const COMMAND_STATUS_OPTIONS = [
  {
    label: 'form.inputs.commandStatus.values.ok',
    value: COMMAND_STATUS.OK,
  },
  {
    label: 'form.inputs.commandStatus.values.failed',
    value: COMMAND_STATUS.FAILED,
  },
  {
    label: 'form.inputs.commandStatus.values.waiting',
    value: COMMAND_STATUS.WAITING,
  },
  // {
  //   label: 'form.inputs.commandStatus.values.pending',
  //   value: COMMAND_STATUS.PENDING,
  // },
  // {
  //   label: 'form.inputs.commandStatus.values.pending_porta',
  //   value: COMMAND_STATUS.PENDING_PORTA,
  // },
  // {
  //   label: 'form.inputs.commandStatus.values.pending_porta_interne',
  //   value: COMMAND_STATUS.PENDING_PORTA_INTERNE,
  // },
];
export const SIM_STATUS_OPTIONS = [
  {
    label: 'form.inputs.commandStatus.values.ok',
    value: COMMAND_STATUS.OK,
  },
  {
    label: 'form.inputs.commandStatus.values.failed',
    value: COMMAND_STATUS.FAILED,
  },
  {
    label: 'form.inputs.commandStatus.values.pending',
    value: COMMAND_STATUS.PENDING,
  },
  {
    label: 'form.inputs.commandStatus.values.pending_porta',
    value: COMMAND_STATUS.PENDING_PORTA,
  },
];
export const SDA_STATUS_OPTIONS = [
  {
    label: 'form.inputs.commandStatus.values.ok',
    value: COMMAND_STATUS.OK,
  },
  {
    label: 'form.inputs.commandStatus.values.failed',
    value: COMMAND_STATUS.FAILED,
  },
  {
    label: 'form.inputs.commandStatus.values.waiting',
    value: COMMAND_STATUS.WAITING,
  },
];
export const TRUNK_STATUS_OPTIONS = [
  {
    label: 'form.inputs.commandStatus.values.ok',
    value: COMMAND_STATUS.OK,
  },
  {
    label: 'form.inputs.commandStatus.values.failed',
    value: COMMAND_STATUS.FAILED,
  },
];
export const MVN_STATUS_OPTIONS = [
  {
    label: 'form.inputs.commandStatus.values.ok',
    value: COMMAND_STATUS.OK,
  },
  {
    label: 'form.inputs.commandStatus.values.failed',
    value: COMMAND_STATUS.FAILED,
  },
  {
    label: 'form.inputs.commandStatus.values.pending',
    value: COMMAND_STATUS.PENDING,
  },
  {
    label: 'form.inputs.commandStatus.values.pending_porta',
    value: COMMAND_STATUS.PENDING_PORTA,
  },
  {
    label: 'form.inputs.commandStatus.values.pending_porta_interne',
    value: COMMAND_STATUS.PENDING_PORTA_INTERNE,
  },
];

export enum COMMAND_REQUEST_TYPE {
  CREATION = 'creation',
  MODIFICATION = 'modification',
  SUPPRESSION = 'suppression',
  ACTIVATION = 'activation',
  INSCRIPTION_ANNUAIRE = 'inscription_annuaire',
  ANNULATION_PORTA = 'annulation_porta',
  REPORT_PORTA = 'report_porta',
  SUSPENSION = 'suspension',
  REACTIVATION = 'reactivation',
  SIMWAP = 'simswap',
  CONSULTATION = 'consultation',
  PACK_DATA = 'pack_data',
  USSD = 'ussd',
  SMS = 'sms',
  UPDATE_BSS = 'update_bss',
  MSISDNSWAP = 'msisdnswap',
  RECHARGER_BALANCES = 'RechargerBalances',
  VISUALISER_BALANCES = 'VisualiserBalances',
  MODIFIER_VALEUR_COMPTEUR_FUP = 'ModifierValeurCompteurFup',
  BLACKLISTER_IMEI = 'BlacklisterImei',
}

export const SDA_REQUEST_TYPE_OPTIONS = [
  {
    label: 'form.inputs.commandRequestType.values.creation',
    value: COMMAND_REQUEST_TYPE.CREATION,
  },
  {
    label: 'form.inputs.commandRequestType.values.modification',
    value: COMMAND_REQUEST_TYPE.MODIFICATION,
  },
  {
    label: 'form.inputs.commandRequestType.values.suppression',
    value: COMMAND_REQUEST_TYPE.SUPPRESSION,
  },
  {
    label: 'form.inputs.commandRequestType.values.inscription_annuaire',
    value: COMMAND_REQUEST_TYPE.INSCRIPTION_ANNUAIRE,
  },
  {
    label: 'form.inputs.commandRequestType.values.annulation_porta',
    value: COMMAND_REQUEST_TYPE.ANNULATION_PORTA,
  },
  {
    label: 'form.inputs.commandRequestType.values.report_porta',
    value: COMMAND_REQUEST_TYPE.REPORT_PORTA,
  },
];
export const TRUNK_REQUEST_TYPE_OPTIONS = [
  {
    label: 'form.inputs.commandRequestType.values.creation',
    value: COMMAND_REQUEST_TYPE.CREATION,
  },
  {
    label: 'form.inputs.commandRequestType.values.modification',
    value: COMMAND_REQUEST_TYPE.MODIFICATION,
  },
  {
    label: 'form.inputs.commandRequestType.values.suppression',
    value: COMMAND_REQUEST_TYPE.SUPPRESSION,
  },
];
export const SIM_REQUEST_TYPE_OPTIONS = [
  {
    label: 'form.inputs.commandRequestType.values.activation',
    value: COMMAND_REQUEST_TYPE.ACTIVATION,
  },
  {
    label: 'form.inputs.commandRequestType.values.modification',
    value: COMMAND_REQUEST_TYPE.MODIFICATION,
  },
  {
    label: 'form.inputs.commandRequestType.values.suppression',
    value: COMMAND_REQUEST_TYPE.SUPPRESSION,
  },
  {
    label: 'form.inputs.commandRequestType.values.inscription_annuaire',
    value: COMMAND_REQUEST_TYPE.INSCRIPTION_ANNUAIRE,
  },
  {
    label: 'form.inputs.commandRequestType.values.consultation',
    value: COMMAND_REQUEST_TYPE.CONSULTATION,
  },
  // {
  //   label: 'form.inputs.commandRequestType.values.annulation_porta',
  //   value: COMMAND_REQUEST_TYPE.ANNULATION_PORTA,
  // },
  // {
  //   label: 'form.inputs.commandRequestType.values.report_porta',
  //   value: COMMAND_REQUEST_TYPE.REPORT_PORTA,
  // },
  // {
  //   label: 'form.inputs.commandRequestType.values.suspension',
  //   value: COMMAND_REQUEST_TYPE.SUSPENSION,
  // },
  // {
  //   label: 'form.inputs.commandRequestType.values.reactivation',
  //   value: COMMAND_REQUEST_TYPE.REACTIVATION,
  // },
  // {
  //   label: 'form.inputs.commandRequestType.values.simswap',
  //   value: COMMAND_REQUEST_TYPE.SIMWAP,
  // },
  // {
  //   label: 'form.inputs.commandRequestType.values.consultation',
  //   value: COMMAND_REQUEST_TYPE.CONSULTATION,
  // },
  // {
  //   label: 'form.inputs.commandRequestType.values.pack_data',
  //   value: COMMAND_REQUEST_TYPE.PACK_DATA,
  // },
  // {
  //   label: 'form.inputs.commandRequestType.values.ussd',
  //   value: COMMAND_REQUEST_TYPE.USSD,
  // },
  // {
  //   label: 'form.inputs.commandRequestType.values.sms',
  //   value: COMMAND_REQUEST_TYPE.SMS,
  // },
  // {
  //   label: 'form.inputs.commandRequestType.values.update_bss',
  //   value: COMMAND_REQUEST_TYPE.UPDATE_BSS,
  // },
  // {
  //   label: 'form.inputs.commandRequestType.values.msisdnswap',
  //   value: COMMAND_REQUEST_TYPE.MSISDNSWAP,
  // },
  // {
  //   label: 'form.inputs.commandRequestType.values.RechargerBalances',
  //   value: COMMAND_REQUEST_TYPE.RECHARGER_BALANCES,
  // },
  // {
  //   label: 'form.inputs.commandRequestType.values.VisualiserBalances',
  //   value: COMMAND_REQUEST_TYPE.VISUALISER_BALANCES,
  // },
  // {
  //   label: 'form.inputs.commandRequestType.values.ModifierValeurCompteurFup',
  //   value: COMMAND_REQUEST_TYPE.MODIFIER_VALEUR_COMPTEUR_FUP,
  // },
  // {
  //   label: 'form.inputs.commandRequestType.values.BlacklisterImei',
  //   value: COMMAND_REQUEST_TYPE.BLACKLISTER_IMEI,
  // },
];

export const MVN_REQUEST_TYPE_OPTIONS = [
  {
    label: 'form.inputs.commandRequestType.values.activation',
    value: COMMAND_REQUEST_TYPE.ACTIVATION,
  },
  {
    label: 'form.inputs.commandRequestType.values.modification',
    value: COMMAND_REQUEST_TYPE.MODIFICATION,
  },
  {
    label: 'form.inputs.commandRequestType.values.suppression',
    value: COMMAND_REQUEST_TYPE.SUPPRESSION,
  },
  {
    label: 'form.inputs.commandRequestType.values.inscription_annuaire',
    value: COMMAND_REQUEST_TYPE.INSCRIPTION_ANNUAIRE,
  },
  {
    label: 'form.inputs.commandRequestType.values.consultation',
    value: COMMAND_REQUEST_TYPE.CONSULTATION,
  },
];
export const MOBILE_FIRST_REQUEST_TYPE_OPTIONS = [];
export const COMMAND_REQUEST_TYPE_OPTIONS = [
  {
    label: 'form.inputs.commandRequestType.values.creation',
    value: COMMAND_REQUEST_TYPE.CREATION,
  },
  {
    label: 'form.inputs.commandRequestType.values.modification',
    value: COMMAND_REQUEST_TYPE.MODIFICATION,
  },
  {
    label: 'form.inputs.commandRequestType.values.suppression',
    value: COMMAND_REQUEST_TYPE.SUPPRESSION,
  },
  {
    label: 'form.inputs.commandRequestType.values.modification',
    value: COMMAND_REQUEST_TYPE.MODIFICATION,
  },
  {
    label: 'form.inputs.commandRequestType.values.suppression',
    value: COMMAND_REQUEST_TYPE.SUPPRESSION,
  },
  {
    label: 'form.inputs.commandRequestType.values.activation',
    value: COMMAND_REQUEST_TYPE.ACTIVATION,
  },
  {
    label: 'form.inputs.commandRequestType.values.inscription_annuaire',
    value: COMMAND_REQUEST_TYPE.INSCRIPTION_ANNUAIRE,
  },
  {
    label: 'form.inputs.commandRequestType.values.annulation_porta',
    value: COMMAND_REQUEST_TYPE.ANNULATION_PORTA,
  },
  {
    label: 'form.inputs.commandRequestType.values.report_porta',
    value: COMMAND_REQUEST_TYPE.REPORT_PORTA,
  },
  {
    label: 'form.inputs.commandRequestType.values.suspension',
    value: COMMAND_REQUEST_TYPE.SUSPENSION,
  },
  {
    label: 'form.inputs.commandRequestType.values.reactivation',
    value: COMMAND_REQUEST_TYPE.REACTIVATION,
  },
  {
    label: 'form.inputs.commandRequestType.values.simswap',
    value: COMMAND_REQUEST_TYPE.SIMWAP,
  },
  {
    label: 'form.inputs.commandRequestType.values.consultation',
    value: COMMAND_REQUEST_TYPE.CONSULTATION,
  },
  {
    label: 'form.inputs.commandRequestType.values.pack_data',
    value: COMMAND_REQUEST_TYPE.PACK_DATA,
  },
  {
    label: 'form.inputs.commandRequestType.values.ussd',
    value: COMMAND_REQUEST_TYPE.USSD,
  },
  {
    label: 'form.inputs.commandRequestType.values.sms',
    value: COMMAND_REQUEST_TYPE.SMS,
  },
  {
    label: 'form.inputs.commandRequestType.values.update_bss',
    value: COMMAND_REQUEST_TYPE.UPDATE_BSS,
  },
  {
    label: 'form.inputs.commandRequestType.values.msisdnswap',
    value: COMMAND_REQUEST_TYPE.MSISDNSWAP,
  },
  {
    label: 'form.inputs.commandRequestType.values.RechargerBalances',
    value: COMMAND_REQUEST_TYPE.RECHARGER_BALANCES,
  },
  {
    label: 'form.inputs.commandRequestType.values.VisualiserBalances',
    value: COMMAND_REQUEST_TYPE.VISUALISER_BALANCES,
  },
  {
    label: 'form.inputs.commandRequestType.values.ModifierValeurCompteurFup',
    value: COMMAND_REQUEST_TYPE.MODIFIER_VALEUR_COMPTEUR_FUP,
  },
  {
    label: 'form.inputs.commandRequestType.values.BlacklisterImei',
    value: COMMAND_REQUEST_TYPE.BLACKLISTER_IMEI,
  },
];

export enum Formats {
  XLSX = 'xlsx',
  PDF = 'pdf',
  JSON = 'json',
}

export const EXPORT_FORMAT_OPTIONS = [
  {
    label: 'form.inputs.format.values.pdf',
    value: Formats.PDF,
  },
  {
    label: 'form.inputs.format.values.json',
    value: Formats.JSON,
  },
  {
    label: 'form.inputs.format.values.xlsx',
    value: Formats.XLSX,
  },
];
export enum COUNTRY {
  DEUTSCHLAND = 'de',
  FRANCE = 'fr',
}
export const COUNTRY_OPTIONS = [
  {
    label: 'form.inputs.countryCode.values.de',
    value: COUNTRY.DEUTSCHLAND,
  },
  {
    label: 'form.inputs.countryCode.values.fr',
    value: COUNTRY.FRANCE,
  },
];

export const TABLE_FILTERS_ALIAS: {
  [x: string]: string;
} = {
  phoneNumberStatus: 'status',
  phoneNumberTypes: 'number_type',
  phoneNumberPortaStatus: 'portability',
  sdaPortaStatus: 'phone_number_sda__portability',
  countryId: 'country',
  countryCode: 'country',
  commandStatus: 'status',
  sdaStatus: 'status',
  trunkStatus: 'status',
  trunkPortaStatus: 'status',
  simStatus: 'status',
  mvnStatus: 'status',
  commandRequestType: 'request_type',
  sdaRequestType: 'request_type',
  trunkRequestType: 'request_type',
  mvnRequestType: 'request_type',
  simRequestType: 'request_type',
  numberSlicesState: 'state',
  customerOffer: 'customer_offer',
  clientCode: 'client_code',
  finalUser: 'final_user',
  clientCodeFinalUser: 'client_code',
};

export const ALLOWED_FILE_TYPES = [
  { type: 'image', name: 'jpg' },
  { type: 'image', name: 'jpeg' },
  { type: 'image', name: 'png' },
  { type: 'application', name: 'pdf' },
];

export const DEFAULT_LANG_HEADER = 'fr';

export enum TRUNK_TYPE {
  VOICE = 'trunk_voix',
  SMS_P2P = 'trunk_sms_p2p',
  SMS_A2P = 'trunk_sms_a2p',
}
