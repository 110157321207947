import axios, { AxiosError, AxiosInstance } from 'axios';
import { config } from '../constants/config';
import { prefLanguageFromStore, readToken, unsetSession } from './storageServices';
import { RESPONSE_STATUS } from '../constants/httpSatus';
import { toast } from 'react-toastify';
import { PATHS } from '../routes/menu';
import i18next from 'i18next';
import { downloadFromDOM } from '../utils';

export const HTTP: AxiosInstance = axios.create({
  baseURL: config.API_BASE_URL(),
  timeout: config.REQUEST_TIMEOUT,
});

HTTP.defaults.headers.post['Content-Type'] = 'application/json';

HTTP.interceptors.request.use(
  async (request) => {
    const preferredLanguage = prefLanguageFromStore();
    request.headers['Accept-Language'] = preferredLanguage;
    const accessToken = readToken('access');
    /* NOTE: Legos don't want refresh token logic for now.
    if (accessToken && isTokenExpired(accessToken)) {
      const refreshToken = readToken('refresh');
      if (refreshToken)
        try {
          const { access } = await refreshSession(refreshToken);
          persistToken('access', access);
          accessToken = access;
        } catch (refreshError) {
          toast.error(i18next.t(`httpResponseMessage.401`));
        }
    }*/
    if (accessToken) request.headers[config.REQUEST_HEADER_AUTH_KEY] = `${config.TOKEN_TYPE} ${accessToken}`;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

HTTP.interceptors.response.use(undefined, (error: AxiosError<any>) => {
  responseErrorLogger(error, true);
  throw error;
});

const formatHeaders = {
  pdf: 'application/pdf',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  json: 'application/json',
};

export const extractDataFrom = (endpoint: string, { format, ...rest }: ExtractOptions): Promise<void> => {
  if (format !== 'json' && format)
    HTTP.get(`${endpoint}`, {
      params: rest,
      responseType: 'blob',
      headers: {
        Accept: formatHeaders[format],
      },
    }).then((res) => {
      if (res.data.size) return downloadFromDOM(res.data, res.headers);
      toast.info(i18next.t('general.texts.emptyData'));
    });
  return Promise.reject();
};

/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-ignore
export function responseErrorLogger(error: AxiosError<any>, authenticated?: boolean) {
  if (error.response?.data?.detail) {
    toast.error(error.response?.data?.detail);
  }
  if (
    [
      RESPONSE_STATUS.NOT_FOUND,
      // RESPONSE_STATUS.FORBIDDEN,
      // RESPONSE_STATUS.SERVER_ERROR,
      // RESPONSE_STATUS.UNAUTHORIZED,
    ].includes(error.response?.status ?? 0)
  ) {
    let errors = error.response?.data?.error;
    if (errors) {
      if (Array.isArray(errors)) errors = errors.join('|');
      toast.error(errors);
    } else toast.error(i18next.t(`httpResponseMessage.${error.response?.status ?? 0}`));
  }

  if (error?.code === 'ERR_NETWORK') {
    toast.error(i18next.t(`httpResponseMessage.0`));
  }
  if (error.response?.status === RESPONSE_STATUS.UNAUTHORIZED) {
    unsetSession();
    window.location.replace(PATHS.AUTH.LOGIN);
  }
}
