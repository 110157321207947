import { DEFAULT_LANG_HEADER } from '../constants';
import { persistStore } from '../store/persistStore';

export const persistToken = (type: 'access' | 'refresh', token: string): void => {
  persistStore.setItem(`${type}Token`, token);
};

export const prefLanguageFromStore = () => {
  return persistStore.getItem('lang') || DEFAULT_LANG_HEADER;
};
export const readToken = (type: 'access' | 'refresh'): string | null => {
  return persistStore.getItem(`${type}Token`) || null;
};

export const readRefreshToken = (): string | null => {
  return persistStore.getItem('refreshToken') || null;
};

export const persistUserPermissions = (permissions: string[]) => {
  return persistStore.setItem('permissions', JSON.stringify(permissions));
};

export const getUserPermissions = (): string[] => {
  const permissions = persistStore.getItem('permissions');
  if (permissions && Array.isArray(JSON.parse(permissions))) {
    return JSON.parse(permissions);
  }
  return [];
};

export const persistUser = (user: IUser): void => {
  persistStore.setItem('user', JSON.stringify(user));
};

export const readUser = (): IUser | null => {
  const userStr = persistStore.getItem('user');
  return userStr ? JSON.parse(userStr) : {};
};

export const deleteToken = (): void => persistStore.removeItem('accessToken');
export const deleteUser = (): void => persistStore.removeItem('user');
export const deletePermissions = (): void => persistStore.removeItem('permissions');

export const unsetSession = (): void => {
  deleteUser();
  deletePermissions();
  deleteToken();
};
